/* eslint-disable prettier/prettier */
import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
// eslint-disable-next-line import/no-named-as-default-member
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import CollegeRoutes from './CollegeRoutes';
import StudentRoutes from './StudentRoutes';
import AdmissionRouts from './AdmissionRouts';
import DashboardRoutes from './DashboardRoutes';
import TeacherRoutes from './TeacherRoutes';
import InstituteRoutes from './InstituteRoutes';
import ReportsRouts from './ReportsRouts';
import PaymentRoutes from './PaymentRoutes';
import PdfRoutes from './PdfRoutes';
import LibraryRoutes from './LibraryRoutes';
import HostelRoutes from './HostelRoutes';
import AnouncementRoutes from './AnouncementRoutes';
import SettingsRoutes from './SettingsRoutes';
import MasterRoutes from './MasterRoutes';
import ActiveAccounts from './ActiveAccounts';
import TransportsRoutes from './TransportsRoutes';
import BudgetAndExpenseRoute from './BudgetAndExpense';
import ExamRoutes from './ExamRoutes';
import SubjectsRoutes from './SubjectsRoutes';
import AssignmentRoutes from './AssignmentRoutes';
import PlacementRouts from './PlacementRouts';
import PaRoutes from './PaRoutes';
import QRCodeRoutes from './QRCodeRoutes';
import HumanResourceRoutes from './HumanResourceRoutes';
import VisitorRoutes from './VisitorRoutes';
import StaffAppraisalRoutes from './StaffAppraisalRoutes';
import WebViewRoutes from './WebViewRoutes';

const PagesLanding = Loadable(lazy(() => import('views/pages/authentication/authentication1/Login1')));

export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <PagesLanding /> },
        AuthenticationRoutes,
        LoginRoutes,
        MainRoutes,
        CollegeRoutes,
        StudentRoutes,
        SubjectsRoutes,
        AdmissionRouts,
        DashboardRoutes,
        TeacherRoutes,
        InstituteRoutes,
        ReportsRouts,
        PaymentRoutes,
        PdfRoutes,
        LibraryRoutes,
        HostelRoutes,
        AnouncementRoutes,
        SettingsRoutes,
        MasterRoutes,
        ActiveAccounts,
        TransportsRoutes,
        BudgetAndExpenseRoute,
        ExamRoutes,
        AssignmentRoutes,
        PlacementRouts,
        PaRoutes,
        HumanResourceRoutes,
        VisitorRoutes,
        StaffAppraisalRoutes,
        WebViewRoutes,
        QRCodeRoutes
    ]);
}
