/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';

const BAMSGradeSheetQr = Loadable(lazy(() => import('views/gradeSheet/ResultViewPage')));
const QRCodeRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
    ),

    children: [
        {
            path: '/result-view',
            element: <BAMSGradeSheetQr />
        },
    ]
};

export default QRCodeRoutes;
