/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import loading from '../../../assets/images/icons/loading.gif';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import DataNotAvailablePage from 'reusable-components/data-not-found';
import { ArrowBack } from '@mui/icons-material';

const headers = {
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
        'Content-Type': 'application/json'
    }
};
const initialValues = {
    isLoading: false,
    staffList: [],
    collegeList: [],
    departmentList: [],
    collegeId: '',
    departmentId: '',
    staffName: ''
};
const StaffListForDUC = () => {
    const instituteId = localStorage.getItem('instituteId');
    const [pageSize, setPageSize] = useState(100);
    const navigate = useNavigate();
    const showMessage = useMessageDispatcher();
    const formikRef = useRef();
    const theme = useTheme();

    const columns = [
        {
            field: 'slNo',
            headerName: '#',
            width: 60,
            renderCell: (index) => index.api.getRowIndex(index.row?.id) + 1
        },
        {
            field: 'staffName',
            headerName: 'Employee Name',
            width: 300,
            renderCell: ({ row }) => (
                <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    <Tooltip title={row?.basicDetail?.staffName}>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate(`/teacher-profile/${row?.id}`, { state: { data: row } });
                            }}
                        >
                            {row?.basicDetail?.staffName
                                ?.toLowerCase()
                                ?.split(' ')
                                ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                                ?.join(' ')}
                        </span>
                    </Tooltip>
                </div>
            )
        },
        {
            field: 'departmentName',
            headerName: 'Department',
            width: 400,
            renderCell: ({ row }) => (
                <div style={{ whiteSpace: 'normal', wordBreak: 'break-word', fontWeight: 600 }}>{row?.staffDomain?.departmentName}</div>
            )
        },
        {
            field: 'designationName',
            headerName: 'Role',
            width: 300,
            renderCell: ({ row }) => (
                <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{row?.staffDomain?.staffCategory?.designationName}</div>
            )
        }
    ];

    const handlePageSizeChange = (params) => {
        const newPageSize = params.page;
        setPageSize(newPageSize);
    };
    const fetchColleges = async () => {
        try {
            const response = await axios.get(`/v1/colleges/list/by-institute/master?instituteId=${instituteId}`, headers);
            if (response.status === 200) {
                if (response.data.code !== '403') {
                    formikRef?.current?.setFieldValue('collegeList', response?.data?.collegesList);
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };
    const fetchDepartments = async (collegeId) => {
        try {
            const response = await axios.get(`/v1/departments/list/by-college?collegeId=${collegeId}`, headers);
            if (response.status === 200) {
                if (response.data.code !== '403') {
                    formikRef?.current?.setFieldValue('departmentList', response?.data?.departmentList);
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };
    const handleSearch = async (values) => {
        try {
            formikRef?.current?.setFieldValue('isLoading', true);
            const response = await axios.get(
                `/v1/staffs/list/filter?instituteId=${instituteId}&collegeId=${values?.collegeId}&departmentId=${
                    values?.departmentId
                }&staffName=${values?.staffName.trim()}&staffType=TEACHING&active=true`,
                headers
            );
            if (response.status === 200) {
                formikRef?.current?.setFieldValue('isLoading', false);
                if (response.data.code !== '403') {
                    formikRef?.current?.setFieldValue('staffList', response?.data?.StaffDetailList);
                }
            }
        } catch (error) {
            formikRef?.current?.setFieldValue('isLoading', false);
            if (!error.response) {
                showMessage({ message: error.message, color: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, color: 'error' });
            }
        }
    };
    useEffect(() => {
        fetchColleges();
        fetchDepartments('');
    }, []);
    return (
        <Formik initialValues={initialValues} onSubmit={handleSearch} innerRef={formikRef}>
            {({ values, handleChange, setFieldValue }) => (
                <Form>
                    <MainCard sx={{ mb: 2 }}>
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowBack fontSize="small" sx={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="h4">Faculty List</Typography>
                        </Box>
                    </MainCard>
                    <MainCard>
                        <Grid container spacing={2} alignItems="center" mb={3}>
                            <Grid item md={3} sm={4} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="college-select">Select School</InputLabel>
                                    <Select
                                        id="collegeId"
                                        name="collegeId"
                                        value={values?.collegeId}
                                        onChange={(event) => {
                                            handleChange(event);
                                            setFieldValue('departmentId', '');
                                            fetchDepartments(event.target.value);
                                        }}
                                        label="Select School"
                                    >
                                        {values?.collegeList?.length === 0 && (
                                            <MenuItem value="">
                                                <em>No School found!</em>
                                            </MenuItem>
                                        )}
                                        {values?.collegeList?.length > 0 && (
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                        )}
                                        {values?.collegeList?.map((item) => (
                                            <MenuItem value={item?.id}>{item?.name?.toUpperCase()}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="department-select">Select Department</InputLabel>
                                    <Select
                                        id="departmentId"
                                        name="departmentId"
                                        value={values?.departmentId}
                                        onChange={(event) => {
                                            handleChange(event);
                                            const value = event.target.value;
                                            const dept = values?.departmentList?.find((elem) => elem?.id === value);
                                        }}
                                        label="Select Department"
                                    >
                                        {values?.departmentList?.length === 0 && (
                                            <MenuItem>
                                                <em>No Department found!</em>
                                            </MenuItem>
                                        )}
                                        {values?.departmentList?.length > 0 && (
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                        )}
                                        {values?.departmentList?.map((item) => (
                                            <MenuItem value={item?.id}>{item?.name?.toUpperCase()}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="staffName"
                                    name="staffName"
                                    label="Employee Name"
                                    value={values.staffName}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button variant="contained" type="submit">
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                                '& .MuiDataGrid-root': {
                                    border: 'none',
                                    '& .MuiDataGrid-cell': {
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                                    },
                                    '& .MuiDataGrid-columnsContainer': {
                                        color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                                    },
                                    '& .MuiDataGrid-columnSeparator': {
                                        color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                                    }
                                }
                            }}
                        >
                            {values?.isLoading ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img height="180px" src={loading} alt="Loading" />
                                </Box>
                            ) : values?.staffList?.length === 0 ? (
                                <DataNotAvailablePage style={{ height: '55vh' }} />
                            ) : (
                                <DataGrid
                                    sx={{
                                        mt: 2,
                                        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
                                            backgroundColor: 'f13223'
                                        },
                                        '& .MuiDataGrid-row:nth-child(even)': {
                                            backgroundColor: '#f0f0f0' // Set your desired even cell background color
                                        },
                                        '& .MuiDataGrid-row:nth-child(odd)': {
                                            backgroundColor: '#ffffff' // Set your desired odd cell background color
                                        },
                                        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                                            borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
                                            // backgroundColor: 'red !important'
                                        },

                                        // eslint-disable-next-line no-dupe-keys
                                        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                                            borderTop: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f1' : '#313130'}`
                                        }
                                    }}
                                    rows={values?.staffList}
                                    columns={columns}
                                    getRowId={(row) => row?.id}
                                    pageSize={pageSize}
                                    pagination
                                    MultipleRowSelection
                                    onPageSizeChange={handlePageSizeChange}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            )}
                        </Box>
                    </MainCard>
                </Form>
            )}
        </Formik>
    );
};

export default StaffListForDUC;
