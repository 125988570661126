import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Electives = Loadable(lazy(() => import('views/electivesubjects/index')));
const AllowElectiveSubjects = Loadable(lazy(() => import('views/electivesubjects/allowElectiveSubjects/index')));
const ElectiveReport = Loadable(lazy(() => import('views/electivesubjects/elective-subject-allocation-report')));

const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const SubjectsRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/subject/electives',
            element: <Electives />
        },
        {
            path: '/subject/allow/electives',
            element: <AllowElectiveSubjects />
        },
        {
            path: '/elective-subject-allocation-report',
            element: <ElectiveReport />
        }
    ]
};
export default SubjectsRoutes;
