import { useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import axios from 'axios';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import { Clear } from '@mui/icons-material';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const headers = {
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
        'Content-Type': 'application/json'
    }
};

const NotificationSection = () => {
    const isRole = localStorage.getItem('role');
    const [notification, setNotification] = useState([]);
    const [ids, setIds] = useState([]);
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const showMessage = useMessageDispatcher();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const fetchNotifications = async () => {
        if (isRole === 'ROLE_STUDENT_ADMISSION') return;
        try {
            const response = await axios.get(`/v1/notification/get?pageNumber=0&pageSize=100`, headers);
            if (response.status === 200) {
                if (response.data.code !== '403') {
                    setNotification(response?.data?.data?.content);
                    const notificationIds = response?.data?.data?.content?.map((item) => item?.id);
                    setIds(notificationIds);
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, type: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, type: 'error' });
            }
        }
    };
    const clearNotifications = async (notificationIds) => {
        try {
            const response = await axios.put(`/v1/notification/clear`, notificationIds, headers);
            if (response.status === 200) {
                if (response.data.code !== '403') {
                    fetchNotifications();
                }
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, type: 'error' });
            } else {
                showMessage({ message: error.response.data.Msg, type: 'error' });
            }
        }
    };
    useEffect(() => {
        fetchNotifications();
    }, []);
    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge badgeContent={notification?.length} color="error" invisible={notification?.length === 0}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out'
                            // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            // color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            // '&[aria-controls="menu-list-grow"],&:hover': {
                            //     background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            //     color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            // }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        style={{
                            backgroundColor: '#FFC700',
                            color: 'black'
                        }}
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard
                                        sx={{ maxWidth: 380 }}
                                        border={false}
                                        elevation={16}
                                        content={false}
                                        boxShadow
                                        shadow={theme.shadows[16]}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{ pt: 2, mb: -3, px: 2 }}
                                                >
                                                    {notification?.length > 0 ? (
                                                        <Grid item>
                                                            <Stack direction="row" spacing={1}>
                                                                <Typography variant="subtitle1">All Notifications</Typography>
                                                                <Chip
                                                                    size="small"
                                                                    label={notification?.length.toString().padStart(2, '0')}
                                                                    sx={{
                                                                        color: theme.palette.background.default,
                                                                        bgcolor: theme.palette.warning.dark
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </Grid>
                                                    ) : (
                                                        <Typography sx={{ fontWeight: 500, color: 'green' }}>
                                                            <em>You have no new notification</em>
                                                        </Typography>
                                                    )}

                                                    <Grid item>
                                                        <Tooltip title="Close">
                                                            <IconButton onClick={handleClose}>
                                                                <Clear sx={{ color: '#00326D' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <PerfectScrollbar
                                                    style={{
                                                        height: '100%',
                                                        maxHeight: 'calc(100vh - 205px)',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    <NotificationList
                                                        notification={notification}
                                                        handleClose={handleClose}
                                                        clearNotifications={clearNotifications}
                                                    />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button
                                                disabled={notification?.length === 0}
                                                onClick={() => clearNotifications(ids)}
                                                size="small"
                                                disableElevation
                                            >
                                                Clear All
                                            </Button>
                                        </CardActions>
                                    </MainCard>
                                )}
                            </Paper>
                            {/* <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">All Notification</Typography>
                                                            <Chip
                                                                size="small"
                                                                label="01"
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography component={Link} to="#" variant="subtitle2" color="primary">
                                                            Mark as all read
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{
                                                        height: '100%',
                                                        maxHeight: 'calc(100vh - 205px)',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ px: 2, pt: 0.25 }}>
                                                                <TextField
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={handleChange}
                                                                    SelectProps={{
                                                                        native: true
                                                                    }}
                                                                >
                                                                    {status.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation>
                                                View All
                                            </Button>
                                        </CardActions>
                                    </MainCard>
                                )}
                            </Paper> */}
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
