import { AddCircleOutline, ArrowBack, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FieldArray, Form } from 'formik';
import React from 'react';

const AddNewAllocation = ({ values, setFieldValue, fetchCourses, fetchSubjects, setIsShow }) => (
    <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <IconButton
                    onClick={() => {
                        setFieldValue('subjectAllocation', [
                            {
                                departmentId: '',
                                courseList: [],
                                courseId: '',
                                semList: 0,
                                sem: 0,
                                subjectList: [],
                                subjectId: ''
                            }
                        ]);
                        setIsShow(false);
                    }}
                >
                    <ArrowBack fontSize="small" sx={{ color: 'black' }} />
                </IconButton>
                <Typography variant="h4">Update Allocation</Typography>
            </Box>
            <Grid item md={6} container display="flex" justifyContent="flex-end">
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="session-select">Select Batch</InputLabel>
                        <Select
                            labelId="session-select"
                            id="sessionId"
                            name="sessionId"
                            value={values?.sessionId}
                            onChange={(event) => setFieldValue('sessionId', event.target.value)}
                            label="Select Batch"
                        >
                            {values?.sessionList?.map((items) => (
                                <MenuItem value={items.id} key={items.id}>
                                    {items.tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>{' '}
            </Grid>
        </Box>
        <Divider sx={{ mb: 2, mt: 2 }} />

        {values?.subjectAllocation?.map((allocation, index) => (
            <FieldArray
                name="subjectAllocation"
                render={(arrayHelpers) => (
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth>
                                <InputLabel>Select Department *</InputLabel>
                                <Select
                                    value={allocation?.departmentId}
                                    label="Select Department *"
                                    onChange={(e) => {
                                        const selectedDepartment = e.target.value;
                                        setFieldValue(`subjectAllocation[${index}].departmentId`, selectedDepartment);
                                        fetchCourses(selectedDepartment, index);
                                        setFieldValue(`subjectAllocation[${index}].courseId`, '');
                                        setFieldValue(`subjectAllocation[${index}].semList`, []);
                                        setFieldValue(`subjectAllocation[${index}].sem`, 0);
                                        setFieldValue(`subjectAllocation[${index}].subjectList`, []);
                                        setFieldValue(`subjectAllocation[${index}].subjectId`, '');
                                    }}
                                >
                                    {values?.departmentList?.map((department) => (
                                        <MenuItem key={department?.id} value={department?.id}>
                                            {department?.name.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth>
                                <InputLabel>Select Program *</InputLabel>
                                <Select
                                    value={allocation?.courseId}
                                    label="Select Program *"
                                    onChange={(e) => {
                                        const selectedCourse = e.target.value;
                                        setFieldValue(`subjectAllocation[${index}].courseId`, selectedCourse);
                                        const semester = allocation?.courseList?.find((value) => value.id === selectedCourse);
                                        setFieldValue(`subjectAllocation[${index}].semList`, semester?.semesterNumber);
                                        setFieldValue(`subjectAllocation[${index}].sem`, 0);
                                        setFieldValue(`subjectAllocation[${index}].subjectList`, []);
                                        setFieldValue(`subjectAllocation[${index}].subjectId`, '');
                                    }}
                                >
                                    {allocation?.courseList?.length === 0 && (
                                        <MenuItem value="">
                                            <em>No Courses Found!</em>
                                        </MenuItem>
                                    )}
                                    {allocation?.courseList?.map((course) => (
                                        <MenuItem key={course?.id} value={course?.id}>
                                            {course?.courseName.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth>
                                <InputLabel>Select Semester *</InputLabel>
                                <Select
                                    value={allocation?.sem}
                                    label="Select Semester *"
                                    onChange={(e) => {
                                        setFieldValue(`subjectAllocation[${index}].sem`, e.target.value);
                                        fetchSubjects(e.target.value, allocation?.courseId, index);
                                        setFieldValue(`subjectAllocation[${index}].subjectId`, '');
                                    }}
                                >
                                    {Array.from({ length: allocation?.semList }, (_, i) => i + 1).map((elem) => (
                                        <MenuItem key={elem} value={elem}>
                                            {elem}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth>
                                <InputLabel>Select Course *</InputLabel>
                                <Select
                                    value={allocation?.subjectId}
                                    label="Select Course *"
                                    onChange={(e) => setFieldValue(`subjectAllocation[${index}].subjectId`, e.target.value)}
                                >
                                    {allocation?.subjectList?.map((subject) => (
                                        <MenuItem key={subject?.id} value={subject?.id}>
                                            {subject?.subjectName} ({subject?.subjectCode})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {index !== 0 && values?.subjectAllocation?.length > 0 && (
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={() => arrayHelpers?.remove(index)}>
                                    <RemoveCircleOutline color="error" />
                                </IconButton>
                            </Grid>
                        )}
                        {index === values?.subjectAllocation?.length - 1 && (
                            <Grid item xs={12} md={1}>
                                <IconButton
                                    onClick={() =>
                                        arrayHelpers?.push({
                                            departmentId: '',
                                            courseList: [],
                                            courseId: '',
                                            semList: 0,
                                            sem: 0,
                                            subjectList: [],
                                            subjectId: ''
                                        })
                                    }
                                >
                                    <AddCircleOutline color="secondary" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                )}
            />
        ))}
        <Form>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" type="submit" color="primary">
                    Save
                </Button>
            </Box>
        </Form>
    </>
);

export default AddNewAllocation;
